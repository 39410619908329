<template>
  <div
    v-show="openOptions"
    @click="openOptions = false"
    class="p-fixed z-2 top-0 left-0 right-0 bottom-0"
  ></div>
  <div class="p-relative">
    <div
      class="bg-white b-all bc-form-border h-46px"
      :class="{
        'br-top-left-5 br-top-right-5 br-bottom-left-0 br-bottom-right-0' : openOptions,
        'br-5' : !openOptions
      }"
    >
      <div
        class="p-relative z-2 d-flex align-center jc-space-between px-16"
        @click="openOptions = !openOptions"
      >
        <div class="h-44px overflow-hidden">
          <MultiOption
            v-for="option in options"
            :key="option.type"
            :option="option"
            :edit-mode="editMode"
            @changeCheckbox="changeCheckbox"
          />
        </div>
        <div class="color-link">
          <Icon class="d-block mt-3" name="arrow-down" />
        </div>
      </div>
      <div
        v-if="options.length"
        v-show="openOptions"
        class="multioptions p-absolute z-2 bg-white top-100 b-left b-right b-bottom bc-form-border left-0 right-0 custom-scrollbar overflow-x-hidden"
      >
        <MultiOption
          v-for="(option, index) in options"
          :key="option.type"
          :option="option"
          full-width="true"
          :edit-mode="editMode"
          :last-elem="index + 1 === options.length"
          limit="60"
          @changeCheckbox="changeCheckbox"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import MultiOption from '@/components/search/MultiOption';

export default {
  components: {
    Icon,
    MultiOption
  },
  emits: ['changeCheckbox'],
  props: {
    options: {
      default: []
    },
    editMode: {
      default: false
    }
  },
  data () {
    return {
      openOptions: false
    };
  },
  methods: {
    changeCheckbox (val) {
      this.$emit('changeCheckbox', val);
    }
  }
};
</script>

<style lang="scss" scoped>
  .multioptions {
    max-height: 165px;
  }
</style>
