<template>
  <div
    v-show="showOption"
    class="p-relative text-base"
    :class="{
      'd-flex align-center jc-space-between lh-40 pl-16 pr-6': fullWidth,
      'd-inline-block align-middle bg-form lh-32 px-8 br-5 my-6 mr-12': !fullWidth,
      'b-bottom bc-form-border': fullWidth && !lastElem
    }"
  >
    <div class="d-flex align-center">
      <Avatar
        v-if="option.type === 'image'"
        :src="option.title"
        class="br-circle w-24px h-24px overflow-hidden mr-8"
      />
      <span>{{ option.type }}: {{ optionTitle }}</span>
      <span class="text-link ml-8">{{ option.price }} SLP</span>
      <a
        v-if="!fullWidth && editMode"
        class="p-absolute br-circle cursor-pointer bg-form-darker color-link h-16px w-16px top--6px right--6px"
        @click.stop.prevent="checkOptionTop"
      >
        <Icon
          class="p-absolute abs-center w-8px h-8px"
          name="cancel-btn-new"
        />
      </a>
    </div>
    <Checkbox
      v-if="fullWidth && editMode"
      class="custom-checkbox"
      v-model="checked"
      :binary="true"
      @change="$emit('changeCheckbox', { ...option, ...{ checked } })"
    />
  </div>
</template>

<script>
import Avatar from '@/components/app/Avatar';
import Icon from '@/components/app/Icon';
import Checkbox from 'primevue/checkbox';

export default {
  components: {
    Avatar,
    Icon,
    Checkbox
  },
  emits: ['changeCheckbox'],
  props: {
    option: {
      default: {}
    },
    fullWidth: {
      default: false
    },
    limit: {
      default: 25
    },
    lastElem: {
      default: false
    },
    editMode: {
      default: false
    }
  },
  watch: {
    option: {
      immediate: true,
      deep: true,
      handler () {
        this.checked = this.option.checked || false;
      }
    }
  },
  data () {
    return {
      checked: false
    };
  },
  computed: {
    optionTitle () {
      if (this.option.title.length > this.limit) return this.option.title.slice(0, this.limit) + '...';
      else return this.option.title;
    },
    showOption () {
      if (this.fullWidth || !this.editMode || this.checked) return true;
      else return false;
    }
  },
  methods: {
    checkOptionTop () {
      this.checked = !this.checked;
      this.$emit('changeCheckbox', { ...this.option, ...{ checked: this.checked } });
    }
  }
};
</script>
