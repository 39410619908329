<template>
  <Layout>
    <template v-slot:sidebar>
      <SidebarCase />
    </template>
    <template v-slot:content>
      <SearchForm />
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/UI/Layout';
import SidebarCase from '@/viewsNew/SidebarCase';
import SearchForm from '@/components/search/Form';

export default {
  components: {
    Layout,
    SidebarCase,
    SearchForm
  }
};
</script>
