<template>
  <div class="modal-background"></div>
  <div class="modal-wrap">
    <div class="modal">
      <Icon class="w-12px h-12px cursor-pointer p-absolute top-12px right-12px" name="cancel-btn" @click="closeModal" />
      <slot></slot>
      <div class="d-flex justify-center">
        <button id="subscribe-modal-back" class="opacity-btn mr-12" @click="closeModal" v-if="!getSubscribe">
          <Icon name="arrow-left-blue" class="mr-8"/>
          Back
        </button>
        <button id="subscribe-modal-select" class="primary-btn" @click="goToPaywall" v-if="getSubscribe || accountState.expiration.days < 0">
          Select plan
        </button>
        <button id="subscribe-modal-more" class="primary-btn" @click="goToPlan" v-else>Add more</button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon';
import { mapGetters } from 'vuex';

export default {
  name: 'AcceptModal',
  components: {
    Icon
  },
  computed: {
    ...mapGetters(['getSubscribe', 'accountState'])
  },
  methods: {
    closeModal () {
      this.$store.commit('setConditionsOfSubscribeModal', false);
    },
    goToPlan () {
      this.$router.push('/plan');
      this.$store.commit('setNoSlPoints', false);
      this.$store.commit('setNoSubscribe', false);
      this.$store.commit('setConditionsOfSubscribeModal', false);
    },
    goToPaywall () {
      if (this.accountState.subscription?.code && this.accountState.subscription.code === 'trial') {
        this.$router.push('/paywall');
      } else {
        this.$router.push('/plan');
      }
      this.$router.push('/paywall');
      this.$store.commit('setNoSlPoints', false);
      this.$store.commit('setNoSubscribe', false);
      this.$store.commit('setConditionsOfSubscribeModal', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
    padding: 32px 48px;
    text-align: center;
}
</style>
