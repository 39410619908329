<template>
  <CaseTitle />
  <form class="bg-white br-5 mb-32" action="">
    <div
      v-if="zeroResults && showZeroResults"
      class="p-relative bg-form br-top-left-5 br-top-right-5 p-24"
    >
      <div class="text-h4">Sorry, but we didn't find anything</div>
      <div class="text-base color-text">Please try changing the input data</div>

      <a
        @click.prevent="showZeroResults = false"
        class="p-absolute top-24px right-24px d-block w-20px h-20px"
      >
        <Icon class="w-100 h-100" name="cancel-btn" />
      </a>
    </div>
    <div class="p-24">
      <div class="text-h4 mb-16">Common Data</div>
      <div class="d-grid gtc-2 gap-12 mb-24">
        <div>
          <label for="" class="label">SUBJECT NAME</label>
          <input
            id="name"
            type="text"
            class="input bg-blue"
            :class="{ 'is-error-double': formError && v$.$errors.find(error => error.$propertyPath === 'form.name') }"
            placeholder="Enter name of person"
            v-model.trim="v$.form.name.$model"
          />
          <div
            v-if="formError && v$.$errors.find(error => error.$propertyPath === 'form.name')"
            class="input-error"
          >
            inavalid name. please do not use digits (0-9) and special characters (!, #, %, etc)
          </div>
        </div>
        <div>
          <label for="" class="label">
            <span>Location</span>
            <span>SEARCH ONLY WITH NAME</span>
          </label>
          <input
            id="location"
            type="text"
            class="input bg-blue"
            placeholder="Enter region / city / town"
            v-model.trim="form.location"
            maxlength="64"
          />
        </div>
      </div>
      <div class="d-flex">
        <!-- <ButtonCheck
          id="education-btn"
          :model="show.education"
          @click.prevent="toggleInput('education')"
        >
          Education
        </ButtonCheck> -->
        <ButtonCheck
          id="company-btn"
          :model="show.company"
          @click.prevent="toggleInput('company')"
        >
          Company
        </ButtonCheck>
      </div>
      <!-- <div
        v-if="show.education"
        class="d-grid gtc-30-auto mt-16"
      >
        <input
          id="education"
          type="text"
          class="input bg-blue br-top-left-5 br-bottom-left-5 br-top-right-0 br-bottom-right-0"
          placeholder="School or university"
          v-model.trim="form.education"
          maxlength="64"
        />
        <div class="bg-form-darker text-caption color-text-lighter br-top-right-5 br-bottom-right-5 lh-48 px-16">
          WORK ONLY WITH NAME, WE WILL SEARCH IN FACEBOOK
        </div>
      </div> -->
      <div
        v-if="show.company"
        class="d-grid gtc-30-auto mt-16"
      >
        <input
          id="company"
          type="text"
          class="input bg-blue br-top-left-5 br-bottom-left-5 br-top-right-0 br-bottom-right-0"
          placeholder="Company name"
          v-model.trim="form.company"
          maxlength="64"
        />
        <div class="bg-form-darker text-caption color-text-lighter br-top-right-5 br-bottom-right-5 lh-48 px-16">
          WORK ONLY WITH NAME, WE WILL SEARCH IN FACEBOOK
        </div>
      </div>
    </div>
    <div class="bg-form p-24">
      <div class="text-h4 mb-16">Individual parameters</div>
      <div class="d-grid gtc-30-auto mb-24">
        <label class="label">Username (alias)</label>
        <div></div>
        <input
          id="username"
          type="text"
          class="input br-top-left-5 br-bottom-left-5 br-top-right-0 br-bottom-right-0"
          :class="{ 'is-error-double': formError && v$.$errors.find(error => error.$propertyPath === 'form.alias') }"
          placeholder="Enter username"
          v-model.trim="v$.form.alias.$model"
        />
        <div
          class="text-caption br-top-right-5 br-bottom-right-5 lh-48 px-16 transition"
          :class="{
            'bg-form-darker color-text-lighter': !v$.$errors.find(error => error.$propertyPath === 'form.alias'),
            'bg-warning color-white': formError && v$.$errors.find(error => error.$propertyPath === 'form.alias')
          }"
        >
          WE WILL SEARCH IN FACEBOOK, LINKEDIN, TWITTER, INSTAGRAM
        </div>
        <div
          v-if="formError && v$.$errors.find(error => error.$propertyPath === 'form.alias')"
          class="input-error grid-column-2"
        >
          Invalid alias, please do not use special characters ($,^,& etc)
        </div>
      </div>
      <div class="d-flex">
        <ButtonCheck
          id="photo-btn"
          :model="show.photo"
          @click.prevent="togglePhotoInput('photo')"
          :disabled="show.photoUrl"
        >
          Photo
        </ButtonCheck>
        <ButtonCheck
          id="photo-url-btn"
          :model="show.photoUrl"
          @click.prevent="togglePhotoInput('photoUrl')"
          :disabled="show.photo"
        >
          Photo URL
        </ButtonCheck>
        <ButtonCheck
          id="profile-url-btn"
          :model="show.profileUrl"
          @click.prevent="toggleInput('profileUrl')"
        >
          Profile URL
        </ButtonCheck>
        <ButtonCheck
          id="profile-id-btn"
          :model="show.profileId"
          @click.prevent="toggleInput('profileId')"
        >
          Profile ID
        </ButtonCheck>
        <ButtonCheck
          id="phone-btn"
          :model="show.phone"
          @click.prevent="toggleInput('phone')"
        >
          Phone number
        </ButtonCheck>
        <ButtonCheck
          id="email-btn"
          :model="show.email"
          @click.prevent="toggleInput('email')"
        >
          Email
        </ButtonCheck>
      </div>
      <div
        v-if="show.photo"
        class="d-flex align-center mt-16"
      >
        <label
          v-if="!form.photo"
          for="upload-photo"
          role="button"
          class="d-flex align-center color-text-lighter color-link_hover transition bg-white jc-space-between br-5 cursor-pointer text-caption h-200px w-260px px-55 py-16"
        >
          <Icon name="upload-icon" />
          <input
            type="file"
            id="upload-photo"
            class="d-none"
            accept=".jpg, .jpeg, .png"
            @change.prevent="onImageChange"
          >
          <span class="upload-photo-text">
            <span>Click here</span>
            <br>
            <span>to upload</span>
          </span>
        </label>
        <div
          v-else
          class="upload__photo"
        >
          <img
            :src="url(form.photo)"
            class="mb-6"
            width="260"
            height="200"
            alt=""
          />
        </div>
        <div class="px-24 py-16">
          <h3
            class="text-link color-black mb-4"
            :style="form.photo && 'color: #585859'"
          >
            {{ (!form.photo) ? 'Upload subject’s photo' : form.photo.name }}
          </h3>
          <div v-if="!form.photo">
            <p class="text-caption color-text-lighter mb-12">SEARCH ONLY WITH NAME</p>
            <p class="text-base mb-12">
              Supported file formats: .png and .jpeg. Max size: {{ imageMaxSize }} MB
            </p>
            <a class="text-link" @click.prevent="togglePhotoInput('photoUrl', true)">Upload by URL</a>
          </div>
          <div class="d-flex mt-12" v-else>
            <button id="edit-photo" type="button" class="text-link mr-16" @click.prevent="updatePhoto">Edit</button>
            <button id="delete-photo" type="button" class="text-link color-warning" @click.prevent="deletePhoto">Delete</button>
          </div>
        </div>
      </div>
      <div
        v-if="show.photo && imageError"
        class="input-error"
      >Inavalid image. Supported file formats: .png and .jpeg. Max size: 1 MB</div>
      <div
        v-if="show.photoUrl"
        class="d-grid gtc-30-auto mt-16"
      >
        <input
          id="photo-url"
          type="text"
          class="input br-top-left-5 br-bottom-left-5 br-top-right-0 br-bottom-right-0"
          :class="{ 'is-error-double': formError && v$.$errors.find(error => error.$propertyPath === 'form.photoUrl') }"
          placeholder="Paste the link"
          v-model.trim="v$.form.photoUrl.$model"
        />
        <div
          class="text-caption br-top-right-5 br-bottom-right-5 lh-48 px-16"
          :class="{
            'bg-form-darker color-text-lighter': !v$.$errors.find(error => error.$propertyPath === 'form.photoUrl'),
            'bg-warning color-white': formError && v$.$errors.find(error => error.$propertyPath === 'form.photoUrl')
          }"
        >
          WORK ONLY WITH NAME, WE WILL SEARCH IN FACEBOOK
          <a
            class="color-link color-link-lighter_hover transition"
            @click.prevent="togglePhotoInput('photo', true)"
          >
            Upload by photo
          </a>
        </div>
        <div
          v-if="formError && v$.$errors.find(error => error.$propertyPath === 'form.photoUrl')"
          class="input-error grid-column-2"
        >
          Invalid link
        </div>
      </div>
      <div
        v-if="show.profileUrl"
        class="d-grid gtc-30-auto mt-16"
      >
        <label class="label">PROFILE URL</label>
        <div></div>
        <input
          id="profile-url"
          type="text"
          class="input br-top-left-5 br-bottom-left-5 br-top-right-0 br-bottom-right-0"
          :class="{ 'is-error-double': formError && v$.$errors.find(error => error.$propertyPath === 'form.profileUrl') }"
          placeholder="Paste the link"
          v-model.trim="v$.form.profileUrl.$model"
          maxlength="94"
        />
        <div
          class="text-caption br-top-right-5 br-bottom-right-5 lh-48 px-16"
          :class="{
            'bg-form-darker color-text-lighter': !v$.$errors.find(error => error.$propertyPath === 'form.profileUrl'),
            'bg-warning color-white': formError && v$.$errors.find(error => error.$propertyPath === 'form.profileUrl')
          }"
        >
          WE WILL SEARCH IN FACEBOOK, LINKEDIN, INSTAGRAM, TWITTER
        </div>
        <div
          v-if="formError && v$.$errors.find(error => error.$propertyPath === 'form.profileUrl')"
          class="input-error grid-column-2"
        >
          Invalid link
        </div>
      </div>
      <div
        v-if="show.profileId"
        class="d-grid gtc-30-auto mt-16"
      >
        <label class="label">PROFILE ID</label>
        <div></div>
        <input
          id="profile-id"
          type="text"
          class="input br-top-left-5 br-bottom-left-5 br-top-right-0 br-bottom-right-0"
          :class="{ 'is-error-double': formError && v$.$errors.find(error => error.$propertyPath === 'form.profileId') }"
          placeholder="Paste the ID"
          v-model.trim="v$.form.profileId.$model"
          maxlength="64"
        />
        <div
          class="text-caption br-top-right-5 br-bottom-right-5 lh-48 px-16"
          :class="{
            'bg-form-darker color-text-lighter': !v$.$errors.find(error => error.$propertyPath === 'form.profileId'),
            'bg-warning color-white': formError && v$.$errors.find(error => error.$propertyPath === 'form.profileId')
          }"
        >
          WE WILL SEARCH IN FACEBOOK, LINKEDIN, INSTAGRAM, TWITTER
        </div>
        <div
          v-if="formError && v$.$errors.find(error => error.$propertyPath === 'form.profileId')"
          class="input-error grid-column-2"
        >
          Invalid id
        </div>
      </div>
      <div
        v-if="show.phone"
        class="d-grid gtc-30-auto mt-16"
      >
        <label class="label">PHONE NUMBER</label>
        <div></div>
        <input
          id="phone-input"
          type="text"
          class="input br-top-left-5 br-bottom-left-5 br-top-right-0 br-bottom-right-0"
          :class="{ 'is-error-double': formError && v$.$errors.find(error => error.$propertyPath === 'form.phone') }"
          placeholder="Type number"
          v-model.trim="v$.form.phone.$model"
        />
        <div
          class="d-flex jc-space-between text-caption br-top-right-5 br-bottom-right-5 lh-48 px-16"
          :class="{
            'bg-form-darker color-text-lighter': !v$.$errors.find(error => error.$propertyPath === 'form.phone'),
            'bg-warning color-white': formError && v$.$errors.find(error => error.$propertyPath === 'form.phone')
          }"
        >
          <div>FACEBOOK / LINKEDIN / INSTAGRAM / TWITTER</div>
          <div>DATA MAY BE OUT OF DATE</div>
        </div>
        <div
          v-if="formError && v$.$errors.find(error => error.$propertyPath === 'form.phone')"
          class="input-error grid-column-2"
        >
          Invalid number, please use valid digit number (+1 000 000 00 00)
        </div>
      </div>
      <div
        v-if="show.email"
        class="d-grid gtc-30-auto mt-16"
      >
        <label class="label">EMAIL</label>
        <div></div>
        <input
          id="email"
          type="text"
          class="input br-top-left-5 br-bottom-left-5 br-top-right-0 br-bottom-right-0"
          :class="{ 'is-error-double': formError && v$.$errors.find(error => error.$propertyPath === 'form.email') }"
          placeholder="Type email"
          v-model.trim="v$.form.email.$model"
          maxlength="64"
        />
        <div
          class="text-caption br-top-right-5 br-bottom-right-5 lh-48 px-16"
          :class="{
            'bg-form-darker color-text-lighter': !v$.$errors.find(error => error.$propertyPath === 'form.email'),
            'bg-warning color-white': formError && v$.$errors.find(error => error.$propertyPath === 'form.email')
          }"
        >
          WE WILL SEARCH IN FACEBOOK, LINKEDIN, TWITTER, INSTAGRAM
        </div>
        <div
          v-if="formError && v$.$errors.find(error => error.$propertyPath === 'form.email')"
          class="input-error grid-column-2"
        >
          Invalid Email, please use valid email (example@domain.com)
        </div>
      </div>
      <div class="d-grid gtc-auto-260px gap-12 align-center mt-24">
        <MultiSelect
          :options="totalOptions"
          :edit-mode="true"
          @changeCheckbox="changeCheckbox"
        />
        <button
          id="search-btn"
          @click.prevent="sendForm"
          :disabled="totalPrice === 0 || searchDisabled"
          class="bttn-primary w-260px"
        >
          <Icon name="search" class="mr-12" />
          Start live search ({{ totalPrice }} SLP)
        </button>
      </div>
    </div>
  </form>

  <SubscribeModal v-if="openSubscribeModal">
    <template v-if="accountState.expiration.days < 0">
      <p class="typography-5 fw-600 let-space-12 color-1 mb-24">Your Gamayun subscription is invalid</p>
      <p class="mb-24">You need to purchase a license to use these features.</p>
    </template>
    <template v-else>
      <p class="typography-5 fw-600 let-space-12 color-1 mb-24">You have run out of SL points</p>
      <p class="mb-24">You need to buy an additional package on the plan page.</p>
    </template>
  </SubscribeModal>
</template>

<script>
import CaseTitle from '@/components/search/CaseTitle';
import ButtonCheck from '@/components/UI/ButtonCheck';
import Icon from '@/components/app/Icon';
import MultiSelect from '@/components/search/MultiSelect';
import { mapActions, mapGetters } from 'vuex';
import { maxLength, email, url } from '@vuelidate/validators';
import { nameInAllLanguages, checkPhoneInputError, aliasValidator, profileUrlValidator, profileIDValidator } from '@/utils/validators';
import { useVuelidate } from '@vuelidate/core';
import searchForm from '@/mixins/searchForm';
import SubscribeModal from '@/components/app/SubscribeModal';

export default {
  components: {
    CaseTitle,
    ButtonCheck,
    Icon,
    MultiSelect,
    SubscribeModal
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler () {
        if (this.$route.params.searchId) {
          await this.setCurrentSearches();
          const form = this.getCurrentSearches.query;
          const newForm = {};
          Object.keys(form).forEach(key => {
            if (key === 'image') newForm.photoUrl = form.image;
            else if (key === 'profile_id') newForm.profileId = form.profile_id;
            else if (key === 'profile_url') newForm.profileUrl = form.profile_url;
            else newForm[key] = form[key];
          });

          this.form = { ...this.form, ...newForm };
          Object.keys(this.form).forEach(key => {
            if (this.form[key]) this.show[key] = true;
          });
        } else {
          this.show = {
            education: false,
            company: false,
            photo: false,
            photoUrl: false,
            profileUrl: false,
            profileId: false,
            phone: false,
            email: false
          };
          this.form = {
            name: '',
            location: '',
            education: '',
            company: '',
            alias: '',
            photo: null,
            photoUrl: '',
            profileUrl: '',
            profileId: '',
            phone: '',
            email: ''
          };
        }
      }
    }
  },
  data () {
    return {
      show: {
        education: false,
        company: false,
        photo: false,
        photoUrl: false,
        profileUrl: false,
        profileId: false,
        phone: false,
        email: false
      },
      form: {
        name: '',
        location: '',
        education: '',
        company: '',
        alias: '',
        photo: null,
        photoUrl: '',
        profileUrl: '',
        profileId: '',
        phone: '',
        email: ''
      },
      v$: useVuelidate(),
      formError: false,
      imageError: '',
      imageMaxSize: process.env.VUE_APP_PHOTO_MAX_UPLOAD_SIZE_MB,
      showZeroResults: true
    };
  },
  mixins: [searchForm],
  validations () {
    return {
      form: {
        name: { maxLength: maxLength(64), nameInAllLanguages },
        alias: { maxLength: maxLength(64), aliasValidator },
        email: { email, maxLength: maxLength(64) },
        phone: { checkPhoneInputError },
        profileUrl: { profileUrlValidator },
        profileId: { profileIDValidator },
        photoUrl: { url }
      }
    };
  },
  computed: {
    ...mapGetters(['accountState', 'getCurrentCase', 'getCurrentSearches', 'openSubscribeModal']),

    totalPrice () {
      let price = 0;
      this.totalOptions.forEach(option => {
        if (option.checked) price += option.price;
      });
      return price;
    },
    beforeSendData () {
      return this.totalOptions.filter(option => option.checked);
    },
    zeroResults () {
      return !!this.$route.query.zeroresults;
    },
    searchDisabled () {
      const obj = {};
      this.beforeSendData.forEach(option => {
        obj[option.type] = true;
      });
      if (obj.photo && !obj.name) return true;
      if (obj.location && !obj.name) return true;
      if (obj.company && !obj.name) return true;
      if (!Object.keys(obj).length) return true;
      return false;
    }
  },
  methods: {
    ...mapActions(['setCurrentSearches', 'setNewSearch']),

    toggleInput (value) {
      this.show[value] = !this.show[value];
      if (!this.show[value]) this.form[value] = '';
    },
    togglePhotoInput (value, isHard = false) {
      if (isHard || ((value === 'photo' && !this.show.photoUrl) || (value === 'photoUrl' && !this.show.photo))) {
        this.show[value] = !this.show[value];
        let emptyVal = '';
        if (value === 'photoUrl') emptyVal = null;
        if (!this.show[value]) this.form[value] = emptyVal;

        if (this.show[value] && value === 'photo') {
          this.show.photoUrl = false;
          this.form.photoUrl = '';
        }
        if (this.show[value] && value === 'photoUrl') {
          this.show.photo = false;
          this.form.photo = null;
        }
      }
    },
    onImageChange (e) {
      this.imageError = '';
      this.form.photo = (e.target.files || e.dataTransfer.files)[0];
      const ext = (this.form.photo.name.match(/\.([^.]+)$/)[1]).toLowerCase();
      if ((ext !== 'png') && (ext !== 'jpg') && (ext !== 'jpeg')) {
        this.imageError = 'The image format must be jpg, jpeg or png';
        this.form.photo = null;
        document.getElementById('upload-photo').value = null;
        if (this.imageError) {
          const timer = setInterval(() => {
            clearInterval(timer);
            this.imageError = '';
          }, 13000);
        }
        return;
      }
      if (this.form.photo.size > (this.imageMaxSize * 1048576)) {
        this.imageError = `Image size exceeds the maximum upload size (${this.imageMaxSize}mb)`;
        this.form.photo = null;
        document.getElementById('upload-photo').value = null;
        if (this.imageError) {
          const timer = setInterval(() => {
            clearInterval(timer);
            this.imageError = '';
          }, 13000);
        }
      }
    },
    updatePhoto () {
      this.form.photo = null;
      this.imageError = '';
      this.$nextTick(() => {
        document.getElementById('upload-photo').click();
      });
    },
    deletePhoto () {
      // this.show.photo = false;
      this.form.photo = null;
      this.imageError = '';
      // this.disabled.photoUrl = !this.disabled.photoUrl;
    },
    getPriceByTransformsList (transforms) {
      const selectedTransforms = [];
      transforms.forEach(tr => {
        const transform = this.accountState.transforms.find(subTr => subTr.name === tr.name);
        if (transform) selectedTransforms.push(transform);
      });
      return selectedTransforms.reduce((previousValue, currentValue) => previousValue + currentValue.price, 0);
    },
    async sendForm () {
      if (this.accountState.expiration.days < 0 || this.accountState.expiration.amount <= 0) {
        this.$store.commit('setConditionsOfSubscribeModal', true);
        return;
      }
      if (this.v$.$invalid) {
        this.formError = true;
      } else {
        this.formError = false;
        this.setNewSearch(this.beforeSendData);
      }
    },
    url (image) {
      if (typeof (image) === 'string') {
        return image;
      } else {
        return URL.createObjectURL(image);
      }
    },
    changeCheckbox (val) {
      this.totalOptions.forEach(option => {
        if (option.type === val.type) option.checked = val.checked;
      });
    }
  }
};
</script>
