<template>
  <div class="d-flex align-center mb-16" v-if="getCurrentCase">
    <div class="case-name-container">
      <Tooltip
        :text="caseName || getCurrentCase.tag"
        :limit="26"
        className="case-length-counter fs-22 fw-600 lh-32"
        v-if="!editPanel"
      />
      <input
        :placeholder="caseName || getCurrentCase.tag"
        type="text"
        id="case-name-input"
        v-model="caseName"
        ref="input"
        @keyup.enter="editCase"
        v-else
      />
    </div>
    <button
      id="edit-case-name"
      type="button"
      v-if="!editPanel"
      @click.prevent="editCaseName"
      class="mb-4 ml-16"
    >
      <Icon name="edit" color="#60BCE3" class="icon-hover" />
    </button>
    <div class="d-flex" v-else>
      <div class="mr-16 color-2 typography-8 ml-16">
        <span :class="{ 'color-warning': caseName.length > limit }">
          {{ caseName.length }}
        </span>
        / {{ limit }}  would be visible</div>
      <button
        type="button"
        @click.prevent="editCase"
        class="mr-8"
        id="save-case-name"
        v-if="caseName.trim().length >= 1"
      >
        <Icon name="ok" />
      </button>
      <button id="cancel-case-name" type="button" @click.prevent="cancelCaseEditing"><Icon name="cancel" /></button>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import { mapActions, mapGetters } from 'vuex';
import Tooltip from '@/components/UI/Tooltip';

export default {
  components: {
    Tooltip,
    Icon
  },
  data () {
    return {
      showTooltip: false,
      caseName: '',
      editPanel: false,
      limit: 26
    };
  },
  computed: {
    ...mapGetters(['getCurrentCase'])
  },
  methods: {
    ...mapActions(['setEditCase']),

    editCaseName () {
      this.editPanel = true;
      setTimeout(() => { this.$refs.input.focus(); }, 0);
    },
    editCase () {
      const caseInfo = {
        fid: this.getCurrentCase.oid,
        tag: this.caseName,
        oldTag: this.getCurrentCase.tag
      };
      this.setEditCase(caseInfo);
      this.editPanel = false;
    },
    cancelCaseEditing () {
      this.caseName = '';
      this.editPanel = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .case-length-counter {
    display: inline-block;
    visibility: hidden;
    white-space: pre;
  }
  #case-name-input, .case-length-counter {
    background-color: #eff4f7;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.02em;
    padding: 0;
    margin-right: 16px;
    min-height: fit-content;
    &::-webkit-input-placeholder {
      color: #585859;
    }
    &::-moz-placeholder {
      color: #585859;
    }
    &:-moz-placeholder {
      color: #585859;
    }
    &:-ms-input-placeholder {
      color: #585859;
    }
    &.edit {
      text-decoration: underline #D6E2F1;
      text-underline-offset: 3px;
      &::-webkit-input-placeholder {
        color: #A0B2BE;
      }
      &::-moz-placeholder {
        color: #A0B2BE;
      }
      &:-moz-placeholder {
        color: #A0B2BE;
      }
      &:-ms-input-placeholder {
        color: #A0B2BE;
      }
    }
  }
</style>
