<template>
  <div
    class="bttn-search mr-12 mb-12"
    :class="{ 'is-active': model }"
  >
    <Icon :name="model ? 'check-new' : 'plus-new'" />
    <slot></slot>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  components: {
    Icon
  },
  props: ['model']
};
</script>
