export default {
  data () {
    return {
      transforms: [
        {
          template: 'facebook_search_profiles_detailed_onlyby_n2',
          name: 'fbDTCsearchPerson3',
          entityType: 'maltego.Person',
          searchType: 'name'
        },
        {
          template: 'instagram_search_person',
          name: 'instagramSearchPerson',
          entityType: 'maltego.Person',
          searchType: 'name'
        },
        {
          template: 'premium_search_people_linkedin',
          name: 'searchLinkedInPersonNew',
          entityType: 'maltego.Person',
          searchType: 'name'
        },
        {
          template: 'twitter_search_users',
          name: 'twitterSearchUsersFromPerson',
          entityType: 'maltego.Person',
          searchType: 'name'
        },
        {
          template: 'linkedin_lookup_by_email_v2',
          name: 'linkedinLookupByEmail2',
          entityType: 'maltego.EmailAddress',
          searchType: 'email'
        },
        {
          template: 'facebook_search_profiles_detailed_v2_graph',
          name: 'facebookSearchProfilesDetailed2graph',
          entityType: 'maltego.SearchPerson',
          searchType: 'name,education'
        },
        {
          template: 'facebook_search_profiles_detailed_v2_graph',
          name: 'facebookSearchProfilesDetailed2graph',
          entityType: 'maltego.SearchPerson',
          searchType: 'name,company'
        },
        {
          template: 'sm2_facebook_by_searchface',
          name: 'sm2FacebookBySearchFace',
          entityType: 'maltego.SearchFace',
          searchType: 'name,photo'
        },
        {
          template: 'sm2_instagram_by_searchface',
          name: 'sm2InstagramBySearchFace',
          entityType: 'maltego.SearchFace',
          searchType: 'name,photo'
        },
        {
          template: 'sm2_linkedin_by_searchface',
          name: 'sm2LinkedinBySearchFace',
          entityType: 'maltego.SearchFace',
          searchType: 'name,photo'
        },
        {
          template: 'sm2_twitter&name_field=fullname&photo_field=photo',
          name: 'sm2TwitterBySearchFace',
          entityType: 'maltego.SearchFace',
          searchType: 'name,photo'
        },
        {
          template: 'facebook_to_entity_v2&query_field_name=query',
          name: 'aliasToFacebook2',
          entityType: 'maltego.Alias',
          searchType: 'alias'
        },
        {
          template: 'instagram_search_person',
          name: 'instagramSearchUser',
          entityType: 'maltego.Alias',
          searchType: 'alias'
        },
        {
          template: 'twitter_alias_to_user',
          name: 'twitterSearchUsersFromAlias',
          entityType: 'maltego.Alias',
          searchType: 'alias'
        },
        {
          template: 'delayed_facebook_search_users',
          name: 'facebookSearchProfilesDetailedDelayedR',
          entityType: 'maltego.delayed.facebook.search.users',
          searchType: 'name,location'
        },
        {
          template: 'facebook_to_entity_v2&query_field_name=query',
          name: 'aliasToFacebook2',
          entityType: 'maltego.Alias',
          searchType: 'profile_url'
        },
        {
          template: 'alias_to_instagram',
          name: 'aliasToInstagram2',
          entityType: 'maltego.Alias',
          searchType: 'profile_url'
        },
        {
          template: 'twitter_alias_to_user',
          name: 'twitterSearchUsersFromAlias',
          entityType: 'maltego.Alias',
          searchType: 'profile_url'
        },
        {
          template: 'linkedin_profile_details&full=0',
          name: 'LinkedinProfileDetail',
          entityType: 'maltego.URL',
          searchType: 'profile_url'
        },
        {
          template: 'linkedin_profile_details&full=0',
          name: 'LinkedinProfileDetail',
          entityType: 'maltego.URL',
          searchType: 'profile_id'
        },
        {
          template: 'facebook_to_entity_v2',
          name: 'fromFBidToEntityV2',
          entityType: 'maltego.facebook.id',
          searchType: 'profile_id'
        },
        {
          template: 'gamayun_twitter_id_to_user',
          name: 'gamayun_twitter_id_to_user',
          entityType: 'maltego.affiliation.Twitter',
          searchType: 'profile_id'
        },
        {
          template: 'gamayun_instagram_id_to_user',
          name: 'gamayun_instagram_id_to_user',
          entityType: 'maltego.instagram.profile',
          searchType: 'profile_id'
        }
      ],
      SLDBTransforms: [
        {
          template: 'webdata_by_new&type=phone',
          name: 'phoneToSldb',
          entityType: 'maltego.PhoneNumber',
          searchType: 'phone'
        },
        {
          template: 'webdata_by_new&type=email',
          name: 'emailToSldb',
          entityType: 'maltego.EmailAddress',
          searchType: 'email'
        }
      ],
      transformsToRunBySLDBResults: [
        {
          template: 'facebook_to_entity_v2',
          name: 'fromFBidToEntityV2',
          entityType: 'maltego.facebook.id'
        },
        {
          template: 'twitter_alias_to_user',
          name: 'twitterAliasToUserV2',
          entityType: 'maltego.Alias'
        },
        {
          template: 'linkedin_alias_to_entities',
          name: 'LinkedinAliasToEntities',
          entityType: 'maltego.Alias'
        },
        {
          template: 'alias_to_instagram',
          name: 'aliasToInstagram2',
          entityType: 'maltego.Alias'
        }
      ],
      totalOptions: []
    };
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler () {
        const totalOptions = [];
        if (this.form.name) {
          totalOptions.push({
            checked: true,
            type: 'name',
            price: this.getPriceByTransformsList(this.transforms.filter(tr => tr.searchType === 'name')),
            title: this.form.name
          });
        }
        if (this.form.location) {
          totalOptions.push({
            checked: true,
            type: 'location',
            price: this.getPriceByTransformsList(this.transforms.filter(tr => tr.searchType === 'name,location')),
            title: this.form.location
          });
        }
        if (this.form.education) {
          totalOptions.push({
            checked: true,
            type: 'education',
            price: this.getPriceByTransformsList(this.transforms.filter(tr => tr.searchType === 'name,education')),
            title: this.form.education
          });
        }
        if (this.form.company) {
          totalOptions.push({
            checked: true,
            type: 'company',
            price: this.getPriceByTransformsList(this.transforms.filter(tr => tr.searchType === 'name,company')),
            title: this.form.company
          });
        }
        if (this.form.photo || this.form.photoUrl) {
          let photoTitle = this.form.photoUrl;
          let photoImage = null;

          if (this.form.photo) {
            photoTitle = '';
            photoImage = this.form.photo;
          }

          totalOptions.push({
            checked: true,
            type: 'photo',
            price: this.getPriceByTransformsList(this.transforms.filter(tr => tr.searchType === 'name,photo')),
            title: photoTitle,
            image: photoImage
          });
        }
        if (this.form.alias) {
          totalOptions.push({
            checked: true,
            type: 'alias',
            price: this.getPriceByTransformsList(this.transforms.filter(tr => tr.searchType === 'alias')),
            title: this.form.alias
          });
        }
        if (this.form.profileUrl) {
          totalOptions.push({
            checked: true,
            type: 'profileUrl',
            price: this.getPriceByTransformsList(this.transforms.filter(tr => tr.searchType === 'profile_url')),
            title: this.form.profileUrl
          });
        }
        if (this.form.profileId) {
          totalOptions.push({
            checked: true,
            type: 'profileId',
            price: this.getPriceByTransformsList(this.transforms.filter(tr => tr.searchType === 'profile_id')),
            title: this.form.profileId
          });
        }
        if (this.form.phone) {
          const phoneTransforms = [];
          phoneTransforms.push(...this.transforms.filter(tr => tr.searchType === 'phone'));
          phoneTransforms.push(...this.SLDBTransforms.filter(tr => tr.searchType === 'phone'));
          phoneTransforms.push(...this.transformsToRunBySLDBResults);

          totalOptions.push({
            checked: true,
            type: 'phone',
            price: this.getPriceByTransformsList(phoneTransforms),
            title: this.form.phone
          });
        }
        if (this.form.email) {
          const emailTransforms = [];
          emailTransforms.push(...this.transforms.filter(tr => tr.searchType === 'email'));
          emailTransforms.push(...this.SLDBTransforms.filter(tr => tr.searchType === 'email'));
          emailTransforms.push(...this.transformsToRunBySLDBResults);

          totalOptions.push({
            checked: true,
            type: 'email',
            price: this.getPriceByTransformsList(emailTransforms),
            title: this.form.email
          });
        }
        this.totalOptions = totalOptions;
      }
    }
  }
};
